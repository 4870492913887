'use client';

import Link from 'next/link';
import { Link as MuiLink, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { getLabelForStatusText } from '@/src/components/Quote/QuoteStatus';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { isSupplierQuoteData } from '../../utils/isSupplierQuoteData';
import { getImageSrc, getPartNumberToDisplay } from '../../utils/recentQuotesDataParsers';
import { RecentQuotesActionBtns } from './RecentQuotesActionBtns';
import { RecentQuotesHeading } from './RecentQuotesHeading';
type DesktopRecentQuotesTableProps = {
  quotes: BuyerDashboardDataResponse['quotes'] | SupplierDashboardDataResponse['quotes'];
  quoteCount: number;
};
export const DesktopRecentQuotesTable = ({
  quotes,
  quoteCount
}: DesktopRecentQuotesTableProps) => {
  const tenantAlias = useTenantAlias();
  const isUnderBreakpointXl = useIsUnderBreakpoint('xl');
  const isSupplierView = isSupplierQuoteData(quotes[0]);
  return <Paper sx={{
    border: 'none',
    p: 2,
    mb: 2
  }} data-sentry-element="Paper" data-sentry-component="DesktopRecentQuotesTable" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
      <RecentQuotesHeading quoteCount={quoteCount} data-sentry-element="RecentQuotesHeading" data-sentry-source-file="DesktopRecentQuotesTable.tsx" />
      <TableContainer sx={{
      px: 0
    }} data-sentry-element="TableContainer" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
        <Table size={isUnderBreakpointXl ? 'small' : 'medium'} data-sentry-element="Table" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
          <TableHead data-sentry-element="TableHead" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
              <TableCell sx={{
              width: '30%'
            }} data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentQuotesTable.tsx">Part&nbsp;#</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentQuotesTable.tsx">{isSupplierView ? 'Customer' : 'Supplier'}</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentQuotesTable.tsx">Status</TableCell>
              <TableCell sx={{
              width: 120
            }} data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentQuotesTable.tsx">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-sentry-element="TableBody" data-sentry-source-file="DesktopRecentQuotesTable.tsx">
            {quotes.map(quote => <TableRow key={quote.id} hover>
                <TableCell>
                  <Stack direction="row" spacing={1} sx={{
                alignItems: 'center'
              }}>
                    <ImageWithFallback tenantAlias={tenantAlias} src={getImageSrc(quote.partRequests)} width={40} height={40} alt="Part image" sx={{
                  flexShrink: 0
                }} />
                    <MuiLink href={`/quote/${quote.id}/display`} sx={{
                  fontSize: 14,
                  fontWeight: 'fontWeightMedium'
                }}>
                      {getPartNumberToDisplay(quote.partRequests)}
                    </MuiLink>
                  </Stack>
                </TableCell>
                <TableCell>
                  {isSupplierQuoteData(quote) ? quote.buyer?.name || quote.contact?.companyName || '' : quote.supplier?.name || ''}
                </TableCell>
                <TableCell>
                  <Link href={`/quote/${quote.id}/display`}>
                    {getLabelForStatusText(quote.statusText)}
                  </Link>
                </TableCell>
                <TableCell>
                  <RecentQuotesActionBtns quoteID={quote.id} quoteWatchObject={quote.watchObject} />
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>;
};