'use client';

import { useQuery } from '@tanstack/react-query';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { CreateNewQuoteButton } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteButton';
import { Error } from '@/src/components/Error/Error';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { CTABoxWrapper } from './components/CTABoxWrapper';
import { RecentConversations } from './components/RecentConversations/RecentConversations';
import { RecentOrdersTable } from './components/RecentOrdersTable/RecentOrdersTable';
import { RecentQuotesTable } from './components/RecentQuotesTable/RecentQuotesTable';
export const VendorDashboardPage = () => {
  const isUnderBreakpointSm = useIsUnderBreakpoint('sm');
  const tenantAlias = useTenantAlias();
  const {
    data,
    isError,
    isLoading
  } = useQuery(spaQueries.dashboard.supplierData(tenantAlias));
  if (isError) {
    return <Error />;
  }
  if (isLoading) {
    return <Box sx={{
      p: 2
    }}>
        <Skeleton variant="rectangular" height={250} sx={{
        my: 2
      }} />
        <Skeleton variant="rectangular" height={250} sx={{
        my: 2
      }} />
      </Box>;
  }
  return <Grid container spacing={{
    xs: 0,
    lg: 3
  }} sx={{
    p: 2
  }} data-sentry-element="Grid" data-sentry-component="VendorDashboardPage" data-sentry-source-file="VendorDashboardPage.tsx">
      <Grid item xs={12} lg={8} data-sentry-element="Grid" data-sentry-source-file="VendorDashboardPage.tsx">
        <RecentQuotesTable quotes={data.quotes} quoteCount={data.quoteCount} data-sentry-element="RecentQuotesTable" data-sentry-source-file="VendorDashboardPage.tsx" />
        <RecentOrdersTable orders={data.orders} orderCount={data.orderCount} data-sentry-element="RecentOrdersTable" data-sentry-source-file="VendorDashboardPage.tsx" />
      </Grid>
      <Grid item xs={12} lg={4} data-sentry-element="Grid" data-sentry-source-file="VendorDashboardPage.tsx">
        <CTABoxWrapper data-sentry-element="CTABoxWrapper" data-sentry-source-file="VendorDashboardPage.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="VendorDashboardPage.tsx">New quote creation</Typography>
          <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="VendorDashboardPage.tsx">
            Create a quote for your customer based on their request
          </Typography>
          <CreateNewQuoteButton data-sentry-element="CreateNewQuoteButton" data-sentry-source-file="VendorDashboardPage.tsx" />
        </CTABoxWrapper>
        {isUnderBreakpointSm ? null : <>
            <CTABoxWrapper>
              <Typography variant="h4">Collaboration</Typography>
              <Typography>Communicate with your customers</Typography>
              <Button href="/conversation/list" variant="contained" sx={{
            width: 'fit-content'
          }}>
                Collaborate
              </Button>
            </CTABoxWrapper>
          </>}
        <RecentConversations conversations={data.conversations} data-sentry-element="RecentConversations" data-sentry-source-file="VendorDashboardPage.tsx" />
      </Grid>
    </Grid>;
};