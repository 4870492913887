import { Box, Typography, Button, Stack } from '@mui/material';
type RecentQuotesHeadingProps = {
  quoteCount: number;
};
export const RecentQuotesHeading = ({
  quoteCount
}: RecentQuotesHeadingProps) => {
  return <Box sx={{
    my: {
      xs: 2,
      sm: 0
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }} data-sentry-element="Box" data-sentry-component="RecentQuotesHeading" data-sentry-source-file="RecentQuotesHeading.tsx">
      <Stack direction={{
      xs: 'column',
      sm: 'row'
    }} spacing={{
      xs: 0,
      sm: 2
    }} sx={{
      alignItems: 'baseline'
    }} data-sentry-element="Stack" data-sentry-source-file="RecentQuotesHeading.tsx">
        <Typography component="h2" variant="h4" data-sentry-element="Typography" data-sentry-source-file="RecentQuotesHeading.tsx">
          Recent Quotes
        </Typography>
        <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="RecentQuotesHeading.tsx">{quoteCount} Total Quotes</Typography>
      </Stack>
      <Button href="/quote/listSupplier" variant="outlined" color="secondary" data-sentry-element="Button" data-sentry-source-file="RecentQuotesHeading.tsx">
        See all ({quoteCount})
      </Button>
    </Box>;
};